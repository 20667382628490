import React from 'react';
import { 
  Box, 
  Container, 
  Grid, 
  Typography 
} from '@mui/material';
import '../DonationGalleryComponent/DonationGalleryComponent.css';
// import DashaDanam from '../../../public/assets/images/Dasha-Danam.jpeg';
// import GhoDaanm from '../../../public/assets/images/gho-daanm.jpg';
// import AshramDonate from '../../../public/assets/images/ashram-donate.jpg';
// import AnnadhanamBrahmins from '../../../public/assets/images/Annadhanam-for-Brahmins.jpg';

import DashaDanam from '../../assets/Dasha-Danam.jpeg';
import GhoDaanm from '../../assets/gho-daanm.jpg';
import AshramDonate from '../../assets/ashram-donate.jpg';
import AnnadhanamBrahmins from '../../assets/Annadhanam-for-Brahmins.jpg';

import { useNavigate } from "react-router-dom";

export default function DonationComponent() {
  // const DashaDanam = require('../../assets/Dasha-Danam.jpeg');
  // const GhoDaanm = require('../../assets/gho-daanm.jpg');
  // const AshramDonate = require('../../assets/ashram-donate.jpg');
  // const AnnadhanamBrahmins = require('../../assets/Annadhanam-for-Brahmins.jpg');

  const navigate = useNavigate();
  function handleClickDonation() {
    navigate("/make-a-donation/my-donation");
  }

  return (
    <>
        <Box className="donation-gallery-page">
            <Container sx={{ px: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
                  <Typography sx={{ backgroundColor: '#686868', padding: '6px 12px', borderRadius: '40px', color: '#ffffff' }}>Donation Gallery</Typography>
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(DashaDanam)}
                            src={DashaDanam}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Dasha-Danam
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(GhoDaanm)}
                            src={GhoDaanm}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Gho-Daan
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(AshramDonate)}
                            src={AshramDonate}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Ashram Donate
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(AnnadhanamBrahmins)}
                            src={AnnadhanamBrahmins}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Annadhanam
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(DashaDanam)}
                            src={DashaDanam}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Dasha-Danam
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(GhoDaanm)}
                            src={GhoDaanm}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Gho-Daan
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(AshramDonate)}
                            src={AshramDonate}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Ashram Donate
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(AnnadhanamBrahmins)}
                            src={AnnadhanamBrahmins}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Annadhanam
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(DashaDanam)}
                            src={DashaDanam}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Dasha-Danam
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(GhoDaanm)}
                            src={GhoDaanm}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Gho-Daan
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(AshramDonate)}
                            src={AshramDonate}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Ashram Donate
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(AnnadhanamBrahmins)}
                            src={AnnadhanamBrahmins}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Annadhanam
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(DashaDanam)}
                            src={DashaDanam}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Dasha-Danam
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(GhoDaanm)}
                            src={GhoDaanm}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Gho-Daan
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    <Box onClick={handleClickDonation} className="donation-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                        <img
                            className='donation-image'
                            // src={String(AshramDonate)}
                            src={AshramDonate}
                            alt="DashaDanam"
                        />
                        <Box sx={{ textAlign: 'center', py: .5 }}>
                            <Box>
                                <Typography className='donation-name' sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                  Ashram Donate
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
        </Box>
    </>
  )
}
