import React from 'react';
import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import MyBooksComponent from '../MyBooksComponent/MyBooksComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';


export default function GetBooksGalleryComponent() {
  return (
    <>
      <HeaderComponent />
      <MyBooksComponent />
      <FooterComponent />
      <BottomAppBarComponent />
    </>
  )
}
