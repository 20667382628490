import axios from "axios";
import qs from "qs";
import { API_REQUEST, apiStart, apiEnd } from "../../actions/api";
import * as config from "../../../assets/js/constant";

const userAPI = axios.create({
  baseURL: config.API_BASE_URL,
  withCredentials: false,
});

const apiMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type.includes(API_REQUEST)) {
    const { feature, isProtected } = action.meta;
    const { method, url, params, body, onSuccess, onError } = action.payload;
    var config = {
      method: method,
      url: url
    };
    // if(getState().login.auth_tokens !=null){
    //   const T = getState().login.auth_tokens.access.token;
    //   const header = {
    //     authorization: `Bearer`
    //    //  authorization: T
    //   };
    // config.headers = header;
    // }
    if (params) {
      config.params = params;
      config.paramsSerializer = (params) => {
        return qs.stringify(params);
      };
    }
    if (body) {
      config.data = body;
    }
    if (feature) {
      dispatch(apiStart(feature));
    }
    var api = userAPI
    api
      .request(config)
      .then(({ data }) => {
        dispatch(onSuccess(data));
      })
      .catch((error) => {
        dispatch(onError(error));
      })
      .finally(() => {
        if (feature) {
          dispatch(apiEnd(feature));
        }
      });
  }
};

export default apiMiddleware;
