import React from 'react';

import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
import "./AboutusDetailsComponent.css";

import {
    Box,
    Container
} from '@mui/material';

export default function AboutusDetailsComponent() {

    return (
        <>
            <HeaderComponent />
            < Box className="puja-gallery-page">
                <Container sx={{ px: 1 }}>

                <div className='div-space'>
                <h3 class="puja-details">About us</h3>
                <p class="sub-title">Sarvamangala Sri Rajarajeswari Ashram <br/>
                    is a 52 year old religious center based in <br/>
                    Chennai, 
                    India.
                </p>
                </div>

                
                <div className='div-space'>
                <p class="sub-title"> 18/29 16th Street, <br/> 
                    TG Nagar,<br/>
                    Nanganallur, <br/>
                    Chennai <br/>
                    INDIA <br/>
                    600061 
                </p>
                </div>
                

                </Container>
            </Box>


            <FooterComponent />
            <BottomAppBarComponent />
        </>
    )

}