import React from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';

import { useNavigate } from "react-router-dom";

import '../TempleGalleryComponent/TempleGalleryComponent.css';
import FooterComponent from '../../common/FooterComponent/FooterComponent';

import ThumbnailImageOne from '../../assets/temple-images/thumbnail-image-one.png';
import ThumbnailImageTwo from '../../assets/temple-images/thumbnail-image-two.png';
import ThumbnailImageThree from '../../assets/temple-images/thumbnail-image-three.png';
import ThumbnailImageFour from '../../assets/temple-images/thumbnail-image-four.png';
import ThumbnailImageFive from '../../assets/temple-images/thumbnail-image-five.png';

export default function TempleGalleryComponent() {

  const navigate = useNavigate();

    function handleClick() {
        navigate("/temple-gallery/gallery-preview");
    }

    function gotoHome() {
      navigate("/");
  }
  return (
    <>
      <Box>
        <Container>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='h4' sx={{ py: 3 }}>Temple Photo Gallery</Typography>
            <Typography className='temple-description' sx={{ pb: 3 }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled it to
              make a type specimen book.
            </Typography>
            <Box sx={{ textAlign:'center', pb: 4 }}>
                <Button onClick={gotoHome} variant="contained" sx={{ borderRadius: '40px', px: 6, py: 1, backgroundColor: '#3875BF' }}>Go To Home</Button>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ mt: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-one.png')}
                      src={ThumbnailImageOne}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-two.png')}
                      src={ThumbnailImageTwo}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-three.png')}
                      src={ThumbnailImageThree}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-four.png')}
                      src={ThumbnailImageFour}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-five.png')}
                      src={ThumbnailImageFive}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-one.png')}
                      src={ThumbnailImageOne}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-two.png')}
                      src={ThumbnailImageTwo}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-three.png')}
                      src={ThumbnailImageThree}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-four.png')}
                      src={ThumbnailImageFour}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-five.png')}
                      src={ThumbnailImageFive}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-one.png')}
                      src={ThumbnailImageOne}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-two.png')}
                      src={ThumbnailImageTwo}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-three.png')}
                      src={ThumbnailImageThree}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-four.png')}
                      src={ThumbnailImageFour}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Box className='photos-section' onClick={handleClick} sx={{ position: 'relative', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                  <img
                      className='gallery-image'
                      // src={require('../../assets/temple-images/thumbnail-image-five.png')}
                      src={ThumbnailImageFive}
                      alt="thumbnailIMage"
                  />
                  <Box sx={{ position: 'absolute', bottom: 0, backgroundColor: '#ffffff', opacity: '80%', width: '100%', p: 1, textAlign: 'center', borderRadius: '0 0 2px 2px' }}>
                    <Typography>Folder Name</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <FooterComponent />
          </Box>
        </Container>
      </Box>
    </>
  )
}
