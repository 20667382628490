import React from 'react';
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';

import { useNavigate } from "react-router-dom";

export default function BookDescriptionComponent() {

    const BookOne = require('../../assets/book-image-one.jpg');

    const navigate = useNavigate();

    function handleClickAddBooks() {
        navigate("/get-books");
    }
  return (
    <>
        <HeaderComponent />
        <Container>
            <Box sx={{ pt: 2, width: 'max-content' }}>
                <Typography sx={{ backgroundColor: '#686868', padding: '6px 12px', borderRadius: '40px', color: '#ffffff' }}>Book Description</Typography>
            </Box>
        </Container>
        <Box sx={{ mt: 3 }}>
          <Container>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={12} md={4}>
                <Box className="user-selected-book">
                    <img
                        className='book-image'
                        src={String(BookOne)}
                        alt="bookImage"
                    />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box className="book-description">
                  <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#3875BF', mb: 2 }}>
                    தேவி மஹாத்ம்யம் (சண்டி) முழுமையான விளக்கவுரை
                  </Typography>
                  <Typography sx={{ lineHeight: 2.3, color: '#8E8E8E' }}>
                    ஸ்ரீதேவீ மாஹாத்ம்யம், துர்கா ஸப்தசதீ, சண்டீ என்ற பெயர்களால் அழைக்கப்படும் அம்பிகையின் உத்தமமான சரிதம் மிகவும் மஹிமை வாய்ந்தது.  இந்த உத்தமமான க்ரந்தத்தின் முழுமையான விளக்கவுரை இந்நூல்.  அபிராமி அந்தாதி, கம்பராமாயணம், திருப்புகழ், திருமந்திரம், திருவாசகம், நாலாயிர திவ்ய ப்ரபந்தம், திருவருட்பா முதலியவற்றில் இருக்கும் பல கருத்துக்களைக் கொண்ட நூல். 
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ textAlign:'center', my: 2 }}>
                <Button onClick={handleClickAddBooks} variant="contained" sx={{ borderRadius: '40px', px: 6, py: 1, backgroundColor: '#3875BF' }}>ADD</Button>
            </Box>
            <Divider />
          </Container>
        </Box>
    </>
  )
}
