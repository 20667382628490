import React from 'react';
import { 
    Box,
    Typography 
} from '@mui/material';
import '../ConfirmMyDonationComponent/ConfirmMyDonationComponent.css';

export default function ConfirmMyDonationComponent() {
  return (
    <>
        <Box
            className='donation-confirmation'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box className="form-control-spacing" sx={{ display: 'flex' }}>
                    <Typography sx={{ pb: 4, fontWeight: '600', color: '#010101' }}>You  wish to make a contribution of</Typography>
                    <Typography sx={{ color: '#8E8E8E', pl: 1 }}>&#x20b9; 20,000 to Dasha Dhanam</Typography>
                    {/* <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box className="label-donation-confirm">
                                <label>Purpose</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography sx={{ color: '#8E8E8E' }}>Dasha Dhanam</Typography>
                        </Grid>
                    </Grid> */}
                </Box>
                {/* <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box className="label-puja-confirm">
                                <label>Amount</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography sx={{ color: '#8E8E8E' }}>20,000</Typography>
                        </Grid>
                    </Grid>
                </Box> */}
            </div>
        </Box>
    </>
  )
}
