import React from 'react';
import { 
  Box, 
  Button, 
  Container, 
  Divider, 
  // Grid, 
  Step, 
  StepContent, 
  StepLabel, 
  Stepper, 
  Typography 
} from '@mui/material';
import '../OrderBooksComponent/OrderBooksComponent.css';

import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import ShippingAddressComponent from '../ShippingAddressComponent/ShippingAddressComponent';
import BooksOrderConfirmComponent from '../BooksOrderConfirmComponent/BooksOrderConfirmComponent';
import PaymentDetailsComponent from '../PaymentDetailsComponent/PaymentDetailsComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';

const steps = [
    {
      label: 'Ship To',
      ShipTo: <ShippingAddressComponent />
    },
    {
      label: 'Confirmation',
      OrderConfirmation: <BooksOrderConfirmComponent />
    },
    {
      label: 'Payment Details',
      PaymentDetails: <PaymentDetailsComponent />
    },
  ];

export default function OrderBooksComponent() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const BookOne = require('../../assets/book-image-one.jpg');

  return (
    <>
        <HeaderComponent />
        {/* <Box sx={{ mt: 3 }}>
          <Container>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={12} md={4}>
                <Box className="user-selected-book">
                    <img
                        className='book-image'
                        src={String(BookOne)}
                        alt="bookImage"
                    />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box className="book-description">
                  <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#3875BF', mb: 2 }}>
                    தேவி மஹாத்ம்யம் (சண்டி) முழுமையான விளக்கவுரை
                  </Typography>
                  <Typography sx={{ lineHeight: 2.3, color: '#8E8E8E' }}>
                    ஸ்ரீதேவீ மாஹாத்ம்யம், துர்கா ஸப்தசதீ, சண்டீ என்ற பெயர்களால் அழைக்கப்படும் அம்பிகையின் உத்தமமான சரிதம் மிகவும் மஹிமை வாய்ந்தது.  இந்த உத்தமமான க்ரந்தத்தின் முழுமையான விளக்கவுரை இந்நூல்.  அபிராமி அந்தாதி, கம்பராமாயணம், திருப்புகழ், திருமந்திரம், திருவாசகம், நாலாயிர திவ்ய ப்ரபந்தம், திருவருட்பா முதலியவற்றில் இருக்கும் பல கருத்துக்களைக் கொண்ட நூல். 
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Container>
        </Box> */}
        <Box className="order-my-books-screen">
            <Container>
                <Box sx={{ py: 2 }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                        <Step key={step.label} >
                          <Box className="stepper-label">
                            <StepLabel>
                              {step.label}
                            </StepLabel>
                          </Box>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                {step.ShipTo}
                                {step.OrderConfirmation}
                                {step.PaymentDetails}
                                <Box className="stepContent-btn" sx={{ mb: 2, textAlign: 'right', mx: 20 }}>
                                      {/* {
                                        ()=>{
                                          if(!index === 0){
                                            <Button
                                              disabled={index === 0}
                                              variant="outlined"
                                              onClick={handleBack}
                                              sx={{ mt: 1, mr: 1 }}
                                          >
                                              Back
                                          </Button>
                                          }
                                        }
                                      } */}
                                        <Button
                                            disabled={index === 0}
                                            variant="outlined"
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                          Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                          {index === steps.length - 1 ? 'Submit' : 'Next'}
                                        </Button>
                                </Box>
                                {/* <hr className='horizontal-line' /> */}
                                <Divider />
                            </StepContent>
                        </Step>
                        ))}
                    </Stepper>
                </Box>
            </Container>
            <FooterComponent />
        </Box>
        <BottomAppBarComponent />
    </>
  )
}
