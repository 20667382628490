import React from 'react';
import { connect } from "react-redux";
import { useEffect } from "react";
import { 
    Box, 
    Typography 
} from '@mui/material';
import '../PujaConfirmationComponent/PujaConfirmationComponent.css';
import { setpujadetail } from '../../store/actions/puja';

const PujaConfirmationComponent =  ({
    pujabookingdetails,
}) => {

   let attendingText;
   switch (pujabookingdetails.pujaPerson) {
       case 'Yes':
           attendingText = "You will be attending in person.";
           break;
       case 'No':
           attendingText = "You will NOT be attending in person.";
           break;
       default:
           attendingText = "";
   }
  return (
    <>
        <Box
            className='puja-confirmation'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box className="form-control-spacing">
                    <Typography sx={{ pb: 4, fontWeight: '600', color: '#010101' }}>Please check details below and confirm:</Typography>
                    <Box className="label-puja-confirm" sx={{ pb: 3 }}>
                        <label>You have booked:</label>
                        <Box sx={{ pb: 2, pt: 2 }}>
                            <Typography sx={{ color: '#8E8E8E' }}>{pujabookingdetails.pujaName} on {pujabookingdetails.pujaDate}</Typography>
                        </Box>
                        <Box>
                        <Typography sx={{ color: '#8E8E8E' }}>{attendingText}</Typography>
                        </Box>
                    </Box>
                    <Box className="label-puja-confirm" sx={{ pb: 3 }}>
                        <label>Sankalpam Details:</label>
                        <Box sx={{ pt: 2 }}>
                            <Typography sx={{ color: '#8E8E8E',whiteSpace: "pre-line" }}>
                               {pujabookingdetails.pujaDetail}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="label-puja-confirm" sx={{ pb: 2 }}>
                        <label>Your Contact Details:</label>
                        <Box sx={{ pb: 2, pt: 2 }}>
                            <Typography sx={{ color: '#8E8E8E' }}>{pujabookingdetails.pujaUserName}</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: '#8E8E8E' }}>{pujabookingdetails.pujaUserContact}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box>
                        <Typography sx={{ pb: 3, fontWeight: '600', color: '#010101' }}>Mailing Address (to send Prasadam):</Typography>
                        <Box>
                            <Typography sx={{ color: '#8E8E8E', pb: 2 }}>{pujabookingdetails.pujamailName}</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: '#8E8E8E', pb: 2, whiteSpace: "pre-line" }}>{pujabookingdetails.pujamailAddress}</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: '#8E8E8E', pb: 2 }}>{pujabookingdetails.pujamailPincode}</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: '#8E8E8E', pb: 2 }}>{pujabookingdetails.pujamailMobile}</Typography>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    </>
  )
}
const mapStateToProps = (state) => {
    return{
        pujabookingdetails: state.puja.pujabookingdetails,
    };
  
   }

export default connect(mapStateToProps,{
    
  })(PujaConfirmationComponent);