import React from 'react';
import { 
    Box, 
    FormControl, 
    Grid, 
    TextField, 
    Typography 
} from '@mui/material';
import '../ShippingAddressComponent/ShippingAddressComponent.css';

export default function ShippingAddressComponent() {
  return (
    <>
        <Box
            className='get-books-orders'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box className="form-control-spacing">
                    <Typography sx={{ pb: 4, fontWeight: '600', color: '#010101' }}>Enter address where books have to be couriered.</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Name</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Address</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Pin Code</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Mobile</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Box>
    </>
  )
}
