import React from 'react';
import { 
    Box, 
    ClickAwayListener,
    IconButton, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Tooltip, 
    Typography 
} from '@mui/material';
import '../BooksOrderConfirmComponent/BooksOrderConfirmComponent.css';
import InfoIcon from '@mui/icons-material/Info';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

function createData(name, Copies, Amount) {
    return { name, Copies, Amount };
  }
  
  const rows = [
    createData('1. Sri Lalitha Sahasranamam', 1, 100),
    createData('2. Kandhar Sashti Kavacham', 1, 101),
    createData('3. The 63 Saivite Saints', 1, 220),
    createData('4. A few hymns on Lord Shiva', 1, 100),
    createData('5. Sri Lalitha Sahasranamam', 2, 80),
    createData('6. Sri Vishnu Sahasranama Stotram', 1, 100),
    createData('7. A few hymns on Devi', 1, 200),
    createData('8. Sandhyavandanam Mantras', 2, 250),
    createData('9. Kandhar Sashti Kavacham', 1, 100),
  ];

export default function BooksOrderConfirmComponent() {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
        <Box
            className='books-ordered-screen'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box>
                    <Typography sx={{ pb: 4, fontWeight: '600', color: '#010101' }}>Books you selected</Typography>
                    <TableContainer sx={{ mb: 4 }}>
                        <Table className='order-confirm-table' sx={{  }} aria-label="spanning table">
                            <TableHead sx={{ border: '1px solid rgb(224 224 224)' }}>
                                <TableRow>
                                    <TableCell align="center" sx={{ fontWeight: '600', color: '#010101' }}>Book Title</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: '600', color: '#010101' }}>Copies</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: '600', color: '#010101' }}>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, border: '1px solid rgb(224 224 224)' }}
                                >
                                    <TableCell className='order-books-name' component="th" scope="row" sx={{ fontWeight: '600' }}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center" sx={{ color: '#8E8E8E', fontSize: '16px' }}>{row.Copies}</TableCell>
                                    <TableCell align="center" sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                        <Box sx={{ mr: 1 }}>
                                            <CurrencyRupeeIcon sx={{ fontSize: '16px', color: '#8E8E8E', mt: 0.5 }} />
                                        </Box>
                                        <Box sx={{ color: '#8E8E8E', fontSize: '16px' }}>
                                            {row.Amount}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow sx={{ borderBottom: '1px solid #D9D9D9' }}>
                                <TableCell rowSpan={3} />
                                <TableCell sx={{ fontWeight: '600' }}>Subtotal</TableCell>
                                <TableCell align="center" sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <Box>
                                        <CurrencyRupeeIcon sx={{ fontSize: '16px', color: '#8E8E8E' }} />
                                    </Box>
                                    <Box sx={{ color: '#8E8E8E', fontSize: '16px' }}>
                                        1,251
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ borderBottom: '1px solid #D9D9D9' }}>
                                <TableCell sx={{ fontWeight: '600' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box>
                                            Shipping
                                        </Box>
                                        <Box>
                                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                                <div className='tooltip-style'>
                                                    <Tooltip
                                                        arrow
                                                        PopperProps={{
                                                        disablePortal: true,
                                                        }}
                                                        onClose={handleTooltipClose}
                                                        open={open}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        title="Amount depends on where we have to courier to"
                                                    >
                                                        <IconButton onClick={handleTooltipOpen}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </ClickAwayListener>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell align="center">
                                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                        <Box>
                                            <CurrencyRupeeIcon sx={{ fontSize: '16px', color: '#8E8E8E', marginRight: '12px' }} />
                                        </Box>
                                        <Box sx={{ color: '#8E8E8E', fontSize: '16px' }}>
                                            200
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: '600', borderBottom: '1px solid #D9D9D9' }}>Total</TableCell>
                                <TableCell align="center" sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', borderBottom: '1px solid #D9D9D9' }}>
                                    <Box>
                                        <CurrencyRupeeIcon sx={{ fontSize: '16px', color: '#8E8E8E' }} />
                                    </Box>
                                    <Box sx={{ color: '#8E8E8E', fontSize: '16px' }}>
                                        1,451
                                    </Box>
                                </TableCell>
                            </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box>
                        <Box className="form-control-spacing">
                            <Typography sx={{ pb: 4, fontWeight: '600', color: '#010101' }}>Shipping Address</Typography>
                            <Box>
                                <Typography sx={{ color: '#8E8E8E', pb: 3 }}>William A. Marion</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ color: '#8E8E8E', pb: 3 }}>3170 Meadow DriveGlasgow, MT 59230</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ color: '#8E8E8E', pb: 3 }}>600061</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ color: '#8E8E8E', pb: 3 }}>+91 61279 71940</Typography>
                            </Box>
                            {/* <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Box className="label-puja-confirm">
                                        <label>Name</label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography sx={{ color: '#8E8E8E' }}>William A. Marion</Typography>
                                </Grid>
                            </Grid> */}
                        </Box>
                        {/* <Box className="form-control-spacing">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Box className="label-puja-confirm">
                                        <label>Address</label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography sx={{ color: '#8E8E8E' }}>3170 Meadow DriveGlasgow, MT 59230</Typography>
                                </Grid>
                            </Grid>
                        </Box> */}
                        {/* <Box className="form-control-spacing">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Box className="label-puja-confirm">
                                        <label>Pin Code</label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography sx={{ color: '#8E8E8E' }}>600061</Typography>
                                </Grid>
                            </Grid>
                        </Box> */}
                        {/* <Box className="form-control-spacing">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Box className="label-puja-confirm">
                                        <label>Mobile</label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography sx={{ color: '#8E8E8E' }}>+91 61279 71940</Typography>
                                </Grid>
                            </Grid>
                        </Box> */}
                    </Box>
                </Box>
            </div>
        </Box>
    </>
  )
}
