import {produce} from "immer";
import { SETPUJA } from "../actions/puja";
import {SETPUJADETAILS} from "../actions/puja";
import {SETPUJABOOKINGDETAILS} from "../actions/puja";
import { SETPAYMENT } from "../actions/puja";

const INITIAL_STATE = {
  pujaList:[],
  pujadetails:[],
  pujabookingdetails:[],
  pujapaymentdetails:[],
};

export const pujaReducer = produce((draft, action) => {
  switch (action.type) {
    case SETPUJA:
      draft.pujaList = action.payload;
      break;
      case SETPUJADETAILS:
        draft.pujadetails = action.payload;
      break;
      case SETPUJABOOKINGDETAILS:
        draft.pujabookingdetails = {...draft.pujabookingdetails, ...action.payload};
      break;
      case SETPAYMENT:
      draft.pujapaymentdetails = action.payload;
      break;
    default:
      return draft;
  }
}, INITIAL_STATE);
