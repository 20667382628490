import React from 'react';
import { 
    Box, 
    Button, 
    Chip, 
    Container, 
    Divider, 
    Grid, 
    Stack, 
    Typography 
} from '@mui/material';
import '../MyBooksComponent/MyBooksComponent.css';
import { makeStyles } from '@material-ui/core/styles';
// import BookOne from '/public/assets/images/Book1.png';
// import BookTwo from '/public/assets/images/Book2.png';

import TempleBookOne from '../../assets/book-image-one.jpg';
import TempleBookTwo from '../../assets/book-image-two.jpg';
import TempleBookThree from '../../assets/book-image-three.jpg';
import TempleBookFour from '../../assets/book-image-four.jpg';
import TempleBookFive from '../../assets/book-image-five.jpg';
import TempleBookSix from '../../assets/book-image-six.jpg';
import TempleBookSeven from '../../assets/book-image-seven.jpg';
import TempleBookEight from '../../assets/book-image-eight.jpg';
import TempleBookNine from '../../assets/book-image-nine.jpg';
import TempleBookTen from '../../assets/book-image-ten.jpg';
import TempleBookEleven from '../../assets/book-image-eleven.jpg';
import TempleBookTwelve from '../../assets/book-image-twelve.jpg';
import TempleBookThirteen from '../../assets/book-image-thirteen.jpg';
import TempleBookFourteen from '../../assets/book-image-fourteen.jpg';
import TempleBookFiveteen from '../../assets/book-image-fiveteen.jpg';
import TempleBookSixteen from '../../assets/book-image-sixteen.jpg';
import TempleBookSeventeen from '../../assets/book-image-seventeen.jpg';

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
    root: {
      borderRadius: '50px',
      color: '#010101 !important',
      borderColor: '#D9D9D9 !important'
    },
    button: {
        "&.active": {
          color:'#ffffff',
        },
    },
  });

export default function MyBooksComponent() {

  const classes = useStyles();

//   const BookOne = require('../../assets/book-image-one.jpg');
//   const BookTwo = require('../../assets/book-image-two.jpg');
//   const BookThree = require('../../assets/book-image-three.jpg');
//   const BookFour = require('../../assets/book-image-four.jpg');
//   const BookFive = require('../../assets/book-image-five.jpg');
//   const BookSix = require('../../assets/book-image-six.jpg');
//   const BookSeven = require('../../assets/book-image-seven.jpg');
//   const BookEight = require('../../assets/book-image-eight.jpg');
//   const BookNine = require('../../assets/book-image-nine.jpg');
//   const BookTen = require('../../assets/book-image-ten.jpg');
//   const BookEleven = require('../../assets/book-image-eleven.jpg');
//   const BookTwelve = require('../../assets/book-image-twelve.jpg');
//   const BookThirTeen = require('../../assets/book-image-thirteen.jpg');
//   const BookFourTeen = require('../../assets/book-image-fourteen.jpg');
//   const BookFiveTeen = require('../../assets/book-image-fiveteen.jpg');
//   const BookSixTeen = require('../../assets/book-image-sixteen.jpg');
//   const BookSevenTeen = require('../../assets/book-image-seventeen.jpg');

  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  function handleClickBooks() {
    navigate("/get-books/my-books-orders");
  }

  function handleClickBooksDescription() {
    navigate("/get-books/book-description");
  }

  const navigate = useNavigate();

  return (
    <>
        <Box className="book-gallery-page">
            <Container sx={{ px: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
                    <Typography sx={{ backgroundColor: '#686868', padding: '6px 12px', borderRadius: '40px', color: '#ffffff' }}>Get A  Book</Typography>
                </Box>
                <Box>
                    <Box sx={{ py: 2 }}>
                        <label>Book Title</label>
                    </Box>
                    <Box>
                        <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                            <Chip className='selectedBooks' sx={{ backgroundColor: '#84AEE1', color: '#ffffff', mr: 1, mb: 2 }} label="தேவி மஹாத்ம்யம் (சண்டி) முழுமையான விளக்கவுரை" onClick={handleClick} />
                            <Chip className='selectedBooks' sx={{ backgroundColor: '#84AEE1', color: '#ffffff', mr: 1, mb: 2 }} label="குத்து விளக்கு பூஜை முறை" onClick={handleClick} />
                            <Chip className='selectedBooks' sx={{ backgroundColor: '#84AEE1', color: '#ffffff', mr: 1, mb: 2 }} label="Laghu Navavarana Puja" onClick={handleClick} />
                            <Chip className='selectedBooks' sx={{ backgroundColor: '#84AEE1', color: '#ffffff', mr: 1, mb: 2 }} label="ஸ்ரீலலிதா த்ரிசதி பாஷ்யம் முழுமையான விளக்கவுரை" onClick={handleClick} />
                            <Chip className='selectedBooks' sx={{ backgroundColor: '#84AEE1', color: '#ffffff', mr: 1, mb: 2 }} label="ஸ்ரீ வீராஞ்ஜநேயர் வைகுண்டநாதப் பெருமாள் நூற்றந்தாதி" onClick={handleClick} />
                            <Chip className='selectedBooks' sx={{ backgroundColor: '#84AEE1', color: '#ffffff', mr: 1, mb: 2 }} label="ஸெளந்தர்யலஹரி பாஷ்யம் முழுமையான விளக்கவுரை" onClick={handleClick} />
                            <Chip className='selectedBooks' sx={{ backgroundColor: '#84AEE1', color: '#ffffff', mr: 1, mb: 2 }} label="ஸ்ரீ விஷ்ணு ஸஹஸ்ர நாமம் முழுமையானவிளக்கவுரை" onClick={handleClick} />
                        </Stack>
                    </Box>
                    <Box sx={{ textAlign:'center', my: 2 }}>
                        <Button onClick={handleClickBooks} variant="contained" sx={{ borderRadius: '40px', px: 6, py: 1, backgroundColor: '#3875BF' }}>Submit</Button>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Divider />
                    </Box>
                </Box>

                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
                        <Typography sx={{ backgroundColor: '#686868', padding: '6px 12px', borderRadius: '40px', color: '#ffffff' }}>Book Gallery</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <Button variant='outlined' className={classes.root} sx={{ borderRadius: '50px' }}>Reset</Button>
                        </Box>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookOne)}
                                    src={TempleBookOne}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='contained' disableElevation className={classes.button} sx={{ borderRadius: '50px', px: 4, backgroundColor: '#84AEE1' }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        தேவி மஹாத்ம்யம் (சண்டி) முழுமையான விளக்கவுரை
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookTwo)}
                                    src={TempleBookTwo}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='contained' disableElevation className={classes.button} sx={{ borderRadius: '50px', px: 4, backgroundColor: '#84AEE1' }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        குத்து விளக்கு பூஜை முறை
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookThree)}
                                    src={TempleBookThree}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='contained' disableElevation className={classes.button} sx={{ borderRadius: '50px', px: 4, backgroundColor: '#84AEE1' }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        Laghu Navavarana Puja
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookFour)}
                                    src={TempleBookFour}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='contained' disableElevation className={classes.button} sx={{ borderRadius: '50px', px: 4, backgroundColor: '#84AEE1' }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                    ஸ்ரீலலிதா த்ரிசதி பாஷ்யம் முழுமையான விளக்கவுரை
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookFive)}
                                    src={TempleBookFive}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='contained' disableElevation className={classes.button} sx={{ borderRadius: '50px', px: 4, backgroundColor: '#84AEE1' }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        ஸ்ரீ வீராஞ்ஜநேயர் வைகுண்டநாதப் பெருமாள் நூற்றந்தாதி
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookSix)}
                                    src={TempleBookSix}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='contained' disableElevation className={classes.button} sx={{ borderRadius: '50px', px: 4, backgroundColor: '#84AEE1' }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        ஸ்ரீலக்ஷ்மி ஸத்யநாராயணப் பெருமாள் நூற்றந்தாதி
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookSeven)}
                                    src={TempleBookSeven}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        ஸெளந்தர்யலஹரி பாஷ்யம் முழுமையான விளக்கவுரை
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookEight)}
                                    src={TempleBookEight}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        ஸ்ரீ விஷ்ணு ஸஹஸ்ர நாமம் முழுமையானவிளக்கவுரை
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookNine)}
                                    src={TempleBookNine}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        ஸ்ரீவித்யா ஸ்ரீகுரு ஸ்ரீதேவி அம்பாள் உபாசனை பற்றிய விளக்கம்
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookTen)}
                                    src={TempleBookTen}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        Srividya Sriguru Sridevi What is Ambal Upasana?
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookEleven)}
                                    src={TempleBookEleven}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        TRIPURA RAHASYA CHARYA KHANDAM
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookTwelve)}
                                    src={TempleBookTwelve}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        ஸ்ரீவாராஹி பூஜாவிதி
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookThirTeen)}
                                    src={TempleBookThirteen}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        Aryan Marriage Rites a la Maharishi Aapastamba
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookFourTeen)}
                                    src={TempleBookFourteen}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        தசமஹாவித்யா ஆவரண பூஜா, ஹோமங்கள் ஸ்ரீவித்யா பூஜா விதானம்
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookFiveTeen)}
                                    src={TempleBookFiveteen}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        ஸ்ரீ லலிதா ஸஹஸ்ர நாமம் முழுமையானவிளக்கவுரை
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookSixTeen)}
                                    src={TempleBookSixteen}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        ஸ்ரீசக்ர நவாவரண பூஜா விதானம்
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box className="book-gallery-section" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                                <img
                                    onClick={handleClickBooksDescription}
                                    className='book-image'
                                    // src={String(BookSevenTeen)}
                                    src={TempleBookSeventeen}
                                    alt="BooksImage"
                                />
                                <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                    <Button variant='outlined' disableElevation className={classes.root} sx={{ borderRadius: '50px', px: 4 }}>ADD</Button>
                                </Box>
                                <Box sx={{ textAlign: 'center', pb: 1.5 }}>
                                    <Typography className='book-name' sx={{ px: 1 }}>
                                        LEARN HOW TO DO SRICHAKRA NAVAVARANA PUJA (ENGLISH & SANSKRIT)
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography className="donation-btn" sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }} variant="button" display="block" gutterBottom>
                                            Suggested Donation | 100
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    </>
  )
}
