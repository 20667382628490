import React from 'react';
import { 
    Box, 
    FormControl, 
    Grid, 
    TextField, 
    Typography 
} from '@mui/material';
import '../MakeMyDonationComponent/MakeMyDonationComponent.css';

export default function MakeMyDonationComponent() {
  return (
    <>
        <Box
            className='puja-confirmation'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Purpose</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Amount</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Your Name  <span className="label-required">*</span></label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Your Contact WhatsApp#</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Alternate Mobile No#</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Typography sx={{ pb: 4, fontWeight: '600', color: '#010101' }}>Mailing Address</Typography>
                    <Box sx={{ mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Name</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField id="outlined-basic" variant="outlined"  />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Address</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField id="outlined-basic" variant="outlined"  />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Pin Code</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField id="outlined-basic" variant="outlined"  />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </Box>
    </>
  )
}
