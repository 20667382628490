import React from 'react';
import { connect } from "react-redux";
import { 
    Box, 
    FormControl, 
    Grid, 
    TextField, 
    Typography,
    FormHelperText
} from '@mui/material';
import '../DevoteeDetailsComponent/DevoteeDetailsComponent.css';
import {getpujadetail} from '../../store/actions/puja.js';
import { useImmer } from 'use-immer';

const DevoteeDetailsComponent = ({

    Pujadetails,
    getpujahomauserdetails,
    setpujahomauserdetails

})=>{
    const ErrorInitialState = {
        error: false,
        message:""
    }
    const [isNameSelected, setIsNameSelected] = useImmer(false);
    const [isContactNumberValid, setIsContactNumberValid] = useImmer(true);
    const [AltContactPersonError, setPersonAltContactPersonError] = useImmer(ErrorInitialState);
    const [MailPersonPinCodeError , setMailPersonPinCodeError] = useImmer(false);
    const [MailPersonMobileError , setMailPersonMobileError] = useImmer(false);
    const [pujaID, setPujaID] = useImmer('');
   
 

    React.useEffect(() => {
        setPujaID(Pujadetails.PujaID);

    }, [Pujadetails])
    

    const handlePersonName = (event) => {
        setpujahomauserdetails((draft) => {
        draft.pujaUserName = event.target.value;
        })
        setIsNameSelected(event.target.value.trim() !== '');   
    };
    const handlePersonContactNumber = (event) => {
        const contactNumber = event.target.value.trim();
        const isValid = /^[0-9]*$/  
        if (contactNumber.length <= 10 && isValid.test(contactNumber)) {        
                setpujahomauserdetails((draft) => {
                    draft.pujaUserContact = contactNumber;
                });
    
            setIsContactNumberValid(true); 
        }else{
            setIsContactNumberValid(false); 
        }
    };
    const handlePersonAltNumber = (event) => {
        const Alternatenumber = event.target.value.trim();
        const isValid = /^[0-9]*$/ 
        if(Alternatenumber.length <= 10 && isValid.test(Alternatenumber)){
            setpujahomauserdetails((draft) => {
                draft.pujaUserAlt = Alternatenumber;
            });
            setPersonAltContactPersonError((draft) => {
                draft.error = false;
                draft.message = ""
            });

        }

        if(Alternatenumber.length > 0 && Alternatenumber.length < 10){
            console.log("ITS CAME TO VALID NUMBER");
            setPersonAltContactPersonError((draft) => {
                draft.error = true;
                draft.message = "Enter a valid mobile number"
            });
        }

        if (Alternatenumber === getpujahomauserdetails.pujaUserContact) {
            setPersonAltContactPersonError((draft) => {
                draft.error = true;
                draft.message = "Contact Number And Alternate Number should not be same"
            });
                
            } 
        }
    
    const handleMailPersonName = (event) => {
        setpujahomauserdetails((draft) => {
        draft.pujamailName = event.target.value;
        })
    };
    const handleMailPersonAddress = (event) => {
        setpujahomauserdetails((draft) => {
        draft.pujamailAddress = event.target.value;
        })
    };
    const handleMailPersonPinCode = (event) => { 
        const pincode = event.target.value;
        const regex = /^[0-9]*$/
        if(pincode.length <= 6 && regex.test(pincode)){
            setpujahomauserdetails((draft) => {
                draft.pujamailPincode = pincode;
            });
        }

        if (pincode.length === 0 ) {
            // Show validation error message
            setMailPersonPinCodeError(true);
        } else {
            // Hide validation error message
            setMailPersonPinCodeError(false);
            // Update user details
        }
    };
    const handleMailPersonMobile = (event) => {
        const personmobile = event.target.value.trim();
        const isValid = /^[0-9]*$/ 
        if(personmobile.length <= 10 && isValid.test(personmobile)){
            setpujahomauserdetails((draft) => {
           draft.pujamailMobile = event.target.value;
           })
           }
        // setpujahomauserdetails((draft) => {
        // draft.pujamailMobile = event.target.value;
        // })
    }
    
    
    


  return (
    <>
        <Box
            className='DevoteeDetails'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Your Name  <span className='label-required'>*</span></label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined" value={getpujahomauserdetails.pujaUserName} onChange={handlePersonName}  />   
                            </FormControl>
                            {getpujahomauserdetails.pujaUserName == "" && (
                            <FormHelperText error>Please enter Name</FormHelperText>
                                )}
                            
                        </Grid>
                    </Grid>
                </Box>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Your Contact WhatsApp#  <span className='label-required'>*</span></label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl  fullWidth >
                                <TextField id="outlined-basic" variant="outlined"  type="text" inputMode='numeric'  value={getpujahomauserdetails.pujaUserContact}  onChange={handlePersonContactNumber}/>
                            </FormControl>
                            {getpujahomauserdetails.pujaUserContact.length < 10 && (
                                    <FormHelperText error>Please enter a valid 10-digit contact number</FormHelperText>
                                )}
                        </Grid>
                    </Grid>
                </Box>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Alternate Mobile No#</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  type="text" value = {getpujahomauserdetails.pujaUserAlt} onChange={handlePersonAltNumber} />
                                {AltContactPersonError.error && (
                                    <FormHelperText error>{ AltContactPersonError.message }</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Typography sx={{ pb: 4, fontWeight: '600', color: '#010101' }}>Please provide an Indian Mailing Address if you want Prasadam to be mailed:</Typography>
                    <Box sx={{ mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Name</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField id="outlined-basic" variant="outlined" value={getpujahomauserdetails.pujamailName} onChange={handleMailPersonName} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="form-control-spacing">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Address</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField multiline rows = "4" id="outlined-basic" variant="outlined" value={getpujahomauserdetails.pujamailAddress} onChange={handleMailPersonAddress} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="form-control-spacing">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Pin Code</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField id="outlined-basic" variant="outlined" type="text" value={getpujahomauserdetails.pujamailPincode} onChange={handleMailPersonPinCode} error = {MailPersonPinCodeError} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="form-control-spacing">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Phone#</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField id="outlined-basic" variant="outlined"  type="text" value = {getpujahomauserdetails.pujamailMobile} onChange={handleMailPersonMobile}  />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </Box>
    </>
  )
}
const mapStateToProps = (state) => {
    return{
        Pujadetails: state.puja.pujadetails
    };
  
   }
export default connect(mapStateToProps,{
    getpujadetail,
    
  })(DevoteeDetailsComponent);