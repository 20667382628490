import {createStore, applyMiddleware,compose} from 'redux'
import {thunk} from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import rootReducer from './reducer'
import { routerMiddleware } from 'react-router-redux'
import {createBrowserHistory as createHistory} from 'history';
import getCoreMiddleware from "./middlewares/core";
import getFeatureMiddleware from "./middlewares/feature";

const initalState = { }

export const history = createHistory();
const myRouterMiddleware = routerMiddleware(history);   
const middleware = [
    thunk,
    ...getFeatureMiddleware(),
    ...getCoreMiddleware(),
    myRouterMiddleware
]

export const store = createStore( rootReducer, initalState, composeWithDevTools(applyMiddleware(...middleware)))