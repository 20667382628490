import React from 'react';

import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
import "./refundComponent.css";

import {
    Box,
    Container
} from '@mui/material';

export default function RefundComponent() {
    return (
        <>
            <HeaderComponent />
            < Box className="puja-gallery-page">
                <Container sx={{ px: 1 }}>
                <h2 class="quote">Refund Policy</h2>
                    
                <h3 class="sub-title">Returns & Refunds</h3>

                    <div>
                    <p>You agree to pay all amounts due on Your Billing Account against devotional service availed by you on our website. Any service order placed through the Website/App for availing any of the reigious services cannot be cancelled and any payment once made will not be refunded.</p>
                    </div>

                    <div>
                   <p>The processing fees or transaction costs, if any, should be borne by the you and you may be charged extra with the pujas and offerings rate.</p>
                    </div>

                    <div>
                  <p>The date selected for the puja/homa or offering cannot be changed after making the payment. However, if the temple due to reasons best known to them, does decide to cancel a Puja/Homa or any other event scheduled by you, an alternative date will be provided to you for the same.</p>
                    </div>

                    <div>
                   <p>If you want to transfer the Puja/Homa service in benefit of some other person, you will need to contact us at srividyatemple@gmail.com , at least three days prior to the Puja/Homa.</p>
                    </div>

                    <div>
                   <p>For purchase of books or other items, our policy is that you should return it within 15 days. AFter that period expires, we will not offer you a refund or exchange. To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</p>
                    </div>

                    <div>
                   <p> Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.</p>
                    </div>

                    <div>
                  <p>If you are approved, then your refund will be processed and a credit will automatically be applied to your card or original method of payment, within 7 business days</p>
                    </div>

                    <div>
                   <p>Late or missing refunds (if applicable)</p>
                    </div>

                    <div>
                   <p> There is often some processing time before a refund is posted. If you’ve done all of this and you still have not received your refund yet, please contact us at support@devdham.com
                    If you still haven’t received a refund, please check your bank account again. Then contact your card company, it may take some time before your refund is officially posted.</p>
                    </div>

                    <div>
                   <p> Exchanges (if applicable)</p>
                    </div>

                    <div>
                   <p> We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email at sridevitemple@gmail.com and send your item to our registered office address as mentioned below.</p>
                    </div>
                    
                    <div>
                    <p>Shipping <br/>
                    To return your product, you should mail your product to registered office address as mentioned below. You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund. Depending on where you live, the time it may take for your exchanged product to reach you, may vary.</p>
                    </div>

                    <div className='div-space'>
                    <h4>Srividya Temple<br/>
                    16th St, TG Nagar, Nanganallur, Chennai 600016 INDIA<br/>
                    Email: <a href="mailto:sridevitemple@gmail.com">sridevitemple@gmail.com</a></h4><br/>
                    </div>
                    
                </Container>
            </Box>


            <FooterComponent />
            <BottomAppBarComponent />
        </>
    )

}
