import React from 'react';

import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
import "./privacyComponent.css";

import {
    Box,
    Container
} from '@mui/material';

export default function PrivacyComponent() {
    return (
        <>
            <HeaderComponent />
            < Box className="puja-gallery-page">
                <Container sx={{ px: 1 }}>

                    <div>
                  <p> This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 which requires publishing the Privacy Policy for the access or usage of https://sridevitemple.com/ , hereinafter referred to as “website” and 'SrideviTemple App', hereinafter referred to as “app”.</p>
                    </div>

                    <div>
                  <p> This Privacy Policy covers the use of the website and the app, including providing you the information of our policies regarding the collection, use and disclosure of Personal Information when you use our service. SrideviTemple, hereinafter referred to as “we”, “our team”, “us” is concerned about the privacy of the users, hereinafter referred to as “you”, “your” or “user” of website and app and have provided this privacy policy to familiarize you with the manner in which we collect, use and disclose your information.</p>
                    </div>

                    <div>
                  <p> We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                    </div>

                    <div>
                   <p> We use your Personal Information for providing and improving the services offered by us. By using the service, you agree to the collection and use of information in accordance with this policy.</p>
                    </div>

                    <div className='div-space'>
                    <p class="sub-title"> 1. What information do we collect? </p>
                    We collect information from you when you register/login on the app/website. While registering, we may ask you for your name, phone number, email, location or address, Date of Birth, Place of Birth and other details needed for us to provide the required service. However, you may visit the app/website anonymously without logging in.
                    </div>

                    <div>
                   <p> When you create an account, you will provide information that could be personally identifiable information, such as your first name, last name, email, mobile number, location or address, Date of Birth, Place of Birth. We may use your contact information to send you information about our services, but only in accordance with your contact preferences. Regardless of your contact preferences, we reserve the right to contact you when we believe it is necessary, such as for account recovery purposes.</p>
                    </div>

                    <div>
                   <p> All information provided by you on the app/website may be retained by us indefinitely, even after you terminate your Account. We may continue to disclose such content to third parties in a manner that does not reveal personally identifiable information, as described in this Privacy Policy.</p>
                    </div>

                    <div>
                   <p>If you choose to share SrideviTemple posts with your friends on social media channels such as Facebook, Whatsapp, we would also display our app install link on your social media channels.</p>
                    </div>

                    <div>
                   <p> In addition to the usage of analytics, the app/website automatically collects usage information, such as the number and frequency of visitors to the app/website. We may use this data in aggregate form, that is, as a statistical measure, but not in a manner that would identify you personally. This type of aggregated data enables us to figure out how often individuals use part of the services so that we can analyse, improve our services and provide better experience to our users.</p>
                    </div>

                    <div className='div-space'>
                    <p class="sub-title"> 2. How do we use and share your information?</p>
                    We share your information with third parties when we believe that the sharing is permitted by you, sharing is reasonably necessary to offer our services, or when legally required to do so. We use the information we collect to try to provide our users an efficient and customized experience. We use the information provided by you in the following manner to provide you with the best possible experience.
                    </div>

                    <div>
                   <p> To manage and improve the service: We use the information we collect to provide our services and features to you, to analyze and improve those services and features, and to provide you with reliable customer support in a short time. To provide customized experience: We endeavour to provide customized experience to each user and this data helps us in making that possible.</p>
                    </div>

                    <div>
                    <p>To contact you: We may contact you in order to obtain feedback on our services and notify you with new features from time to time. Notifications: We may send you notifications from time to time as and when new content is posted on the app/website and when we introduce new features on the app/ website.</p>
                    </div>

                    <div>
                    <p> To share the content: We use our social media plugins inside the app/website to let you post the app content on social media channels. To post content: Some users need to grant permission to app/website to allow access of photos, media and files or access camera on their devices to post the content on the app/website.</p>
                    </div>

                    <div>
                    <p>To respond to legal requests and prevent harm: We may disclose information pursuant to subpoenas, court orders, or other requests (including criminal and civil matters) if we have a good faith belief that the response is required by law.</p>
                    </div>

                    <div className='div-space'>
                    <p class="sub-title">  3. How do we protect visitor information?</p>
                    We implement a variety of security measures to maintain the safety of your personal information. Your Personal Information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems and are required to keep the information confidential.
                    </div>

                    <div className='div-space'>
                    <p class="sub-title"> 4. Public Information about your Activity on the Website/App </p>
                    If you choose to provide any personally identifiable information using certain public features of the app/website, then that information is governed by the privacy settings of those particular features and may be publicly available. Individuals reading such information may use or disclose it to other individuals or entities without our control and without your knowledge, and search engines may index that information.
                    </div>

                    <div className='div-space'>
                    <p class="sub-title"> 5. Do we disclose the information we collect to outside parties? </p>
                    We do not sell, trade, or otherwise transfer your personally identifiable information to thirdparties, unless we provide you with advance notice in relation to the same. The term “outside parties” does not include website hosting partners, our technology partners and other parties who assist us in operating the app/website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
                    </div>

                    <div>
                    <p>We may also release your information, when we believe that release is appropriate to comply with the law, enforce our app/website policies, or protect ours or others’ rights, property or safety.</p>
                    </div>

                    <div>
                    <p> Non-personally identifiable visitor information may be provided to other parties for marketing, advertising, benchmarking and internal quality control measures for improving experience for our uses.</p>
                    </div>

                    <div className='div-space'>
                    <p class="sub-title">6. Third party links </p>   
                    In an attempt to provide you our service with increased value, we may include third party links/integration on our app/website. These linked apps/websites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked apps/websites. Nonetheless, we seek to protect the integrity of our app/website and welcome any feedback about these linked apps/websites.
                    </div>

                    <div className='div-space'>
                     <p class="sub-title"> 7. Changes to our policy </p>
                    If we decide to change our Privacy Policy, we will post those changes on this page. Policy changes will apply only to information collected after the date of the change. We recommend you to visit this page on a regular basis to stay updated on changes we make from time to time.
                    </div>

                    <div className='div-space'>
                    <p class="sub-title">8. Online Policy only </p>
                    This online Privacy Policy Applies only to information collected through our app/website.
                    </div>

                    <div className='div-space'>
                    <p class="sub-title"> 9. Your consent </p>
                    By using our app/website, you consent to our Privacy Policy.
                    </div>

                    <div className='div-space'>
                    <p class="sub-title"> Disclaimer </p>
                    The information contained in this app/website is for general information purposes only. While we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability of the content on the app/website. Any reliance you place on such information is at your own risk. In no event, we will be liable for any loss or damage including without limitation, indirect or consequential loss or damage arising from loss of data or profits arising out of it.
                    </div>

                </Container>
            </Box>


            <FooterComponent />
            <BottomAppBarComponent />
        </>
    )
}