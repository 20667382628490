import React, { useState, useCallback } from 'react';
// import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../../data/photos";
// import templePhotos from "../../data/templePhotos.json";
import {
    Box,
    Breadcrumbs,
    Container,
    // Grid,
    Link,
    Typography
} from '@mui/material';
import FooterComponent from '../../common/FooterComponent/FooterComponent';

export default function GalleryPreviewComponent() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, description, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

//   const animals = ["Dog", "Bird", "Cat", "Mouse", "Horse", "Dog", "Bird", "Cat", "Mouse", "Horse"];


  return (
    <>
        <Box>
            <Container>
                <Box sx={{ py: 4 }}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href='/temple-gallery'>
                            Gallery
                        </Link>
                        <Typography color="text.primary">Folder Name</Typography>
                    </Breadcrumbs>
                </Box>

                <div>
                    {/* <Grid container spacing={1}>
                        {animals.map(animal => (
                            <Grid item xs={6} md={2.4}>
                                {animal}
                            </Grid>
                        ))}
                    </Grid> */}

                    {/* {templePhotos.images.map((image, i) => (
                        <div className="item" key={i} templePhotos={templePhotos} onClick={openLightbox}>
                            <img src={`${image.url}`} alt={image.description} key={i} />
                        </div>
                    ))} */}

                    <Gallery
                        photos={photos}
                        onClick={openLightbox}
                        alt={photos.description}
                    />
                    <ModalGateway>
                        {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                            currentIndex={currentImage}
                            views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                            />
                        </Modal>
                        ) : null}
                    </ModalGateway>
                </div>

                {/* <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-one.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-two.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-three.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-four.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-five.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-one.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-two.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-three.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-four.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-five.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-one.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-two.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-three.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-four.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Box sx={{ width: '225px', height: '225px', border: '1px solid #8E8E8E', borderRadius: '4px', cursor: 'pointer' }}>
                                <img
                                    className='gallery-image'
                                    src={require('../../assets/temple-images/thumbnail-image-five.png')}
                                    alt="thumbnailIMage"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box> */}
                <Box>
                    <FooterComponent />
                </Box>
            </Container>
        </Box>
    </>
  )
}
