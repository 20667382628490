import React from 'react';

import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
import "./termsComponent.css";

import {
    Box,
    Container
} from '@mui/material';

export default function TermComponent() {
    return (
        <>
            <HeaderComponent />
            < Box className="puja-gallery-page">
                <Container sx={{ px: 1 }}>
                    <h2 class="quote">Terms and Conditions</h2>

                    <h3 class="sub-title"> Last updated: Jul 21, 2024</h3>

                    <h4 class="puja-details">Welcome to Srividya Temple!</h4>

                    <div>
                        <p>These terms and conditions outline the rules and regulations for the use of Srividya Temple's Website and services, located at www.srividyatemple.com</p>
                    </div>

                    <div>
                        <p>By accessing this website and engaging our services, we assume you accept these terms and conditions. Do not continue to use Srividya Temple if you do not agree to take all of the terms and conditions stated on this page.</p>
                    </div>

                    <div className='div-space'>
                        <p class="sub-title">1. Definitions</p>
                        - "Client" Refers to the individual that engages Srividya Temple for services.<br/>
                        - "Service" Refers to any service provided by Srividya Temple as described in the service agreement or on our website.<br/>
                        - "Trust" Refers to NSR Trust, Srividya Temple, 16th St, TG Nagar, Nanganallur, Chennai 600016.
                    </div>

                    <div className='div-space'>
                    <p class="sub-title">2. Scope of Services</p>
                    - The services provided by Srividya Temple will be detailed in a separate service agreement signed by both the Client and the Trust. <br/>
                    - The Trust reserves the right to refuse or discontinue service to any Client for any reason.
                    </div>

                    <div className='div-space'>
                    <p class="sub-title">3. Payment Terms</p>
                    - All fees for services will be detailed in the service agreement.<br/>
                    - Payment is due upon receipt of the invoice unless otherwise agreed upon in writing.<br/>
                    - Late payments may incur interest charges as specified in the service agreement.<br/>
                    </div>

                    <div className='div-space'>
                    <p class="sub-title">4. Confidentiality</p>
                    - Both parties agree to keep confidential all information obtained from the other party that is not publicly available.<br/>
                    - This obligation remains in effect for a period of 1 year after the termination of the service agreement.<br/>
                    </div>

                    <div className = 'div-space'>
                    <p class="sub-title">5. Intellectual Property</p>
                    - All intellectual property created by Srividya Temple during the provision of services remains the property of the Trust unless otherwise agreed in writing.<br/>
                    - The Client is granted a non-exclusive, non-transferable license to use the deliverables solely for their own internal purposes.<br/>
                    </div>

                    <div className = 'div-space'>
                    <p class="sub-title">6. Limitation of Liability</p>
                    - Srividya Temple is not liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to the services provided.<br/>
                    - The Trust’s total liability to the Client for any claim arising out of or in connection with the services will not exceed the amount paid by the Client to the Trust in the six months preceding the claim.<br/>
                    </div>
                    
                    <div className = 'div-space'>
                    <p class="sub-title">7. Termination</p>
                    - Either party may terminate the service agreement with 30 days' written notice.<br/>
                    - Upon termination, the Client will pay for all services rendered up to the date of termination.<br/>
                    </div>

                    <div className = 'div-space'>
                    <p class="sub-title">8. Governing Law</p>
                    - These terms and conditions are governed by and construed in accordance with the laws of India.<br/>
                    - Any disputes arising out of or in connection with these terms will be resolved in the courts of Chennai, Tamilnadu.<br/>
                    </div>
                    
                    <div className = 'div-space'>
                    <p class="sub-title">9. Changes to Terms and Conditions</p>
                    - Srividya Temple reserves the right to modify these terms and conditions at any time. Any changes will be posted on our website.<br/>
                    - Continued use of our services after any changes constitutes acceptance of the new terms and conditions.<br/>
                    </div>

                    <div className = 'div-space'>
                    <p class="sub-title">10. Contact Information</p>
                    - If you have any questions about these terms and conditions, please contact us at srividyatemple@gmail.com.<br/>
                    </div>

                    By using our services, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.
 
                    <p>---</p>
                    
                    <div className='div-space'>
                    <h4>Srividya Temple<br/>
                    16th St, TG Nagar, Nanganallur, Chennai 600016 INDIA<br/>
                    Email: <a href="mailto:sridevitemple@gmail.com">sridevitemple@gmail.com</a></h4><br/>
                    </div>

                </Container>
            </Box>


            <FooterComponent />
            <BottomAppBarComponent />
        </>
    )
}
