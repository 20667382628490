import React from 'react';
import { useEffect } from "react";
import { connect } from "react-redux";
import { 
    Box, 
    Container, 
    Grid, 
    Typography 
} from '@mui/material';
import '../PujaBookingComponent/PujaBookingComponent.css';
import {getpujas} from '../../store/actions/puja.js';

import { useNavigate } from "react-router-dom";
import * as config from "../../assets/js/constant.js";

import NoDataFound from '../../assets/no-data-found.png';

const  PujaBookingComponent = ({
    getpujas,
    pujaList

}) => {
    // const PujaImage = require('../../assets/puja-image-one.png');
    // const HomaImage = require('../../assets/puja-image-two.png');

    const navigate = useNavigate();

    useEffect(() => {
        var pujaData = getpujas();
        console.log(pujaData)
    }, [])
    console.log(pujaList);
    function handleClickPujaBooking(id) {
        navigate(`/puja-gallery/my-puja-booking/${id}`);
    }
    
    return (
        <Box className="puja-gallery-page">
            <Container sx={{ px: 1 }}>
                <Box sx={{ py: 2, width: 'max-content' }}>
                    <Typography sx={{ backgroundColor: '#686868', padding: '6px 12px', borderRadius: '40px', color: '#ffffff' }}>Puja/Homa</Typography>
                </Box>

                <Box>
                    <Grid container spacing={1}>
                        {pujaList && pujaList.length > 0 ? (
                            pujaList.map((puja, index) => (
                                <Grid item xs={6} md={2.4} key={index}>
                                    <Box
                                        onClick={() => handleClickPujaBooking(puja.PujaID)}
                                        className="puja-gallery-section"
                                        sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}
                                    >
                                        <img
                                            className='puja-image'
                                            src={config.image + puja.Image}
                                            alt="pujaImage"
                                        />
                                        <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                            <Typography className='puja-homa-name'>
                                                {puja.PujaName}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ textAlign: 'center' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Typography
                                                    className="donation-btn"
                                                    sx={{ border: '1px solid #3875BF', borderRadius: '40px', textTransform: 'capitalize', mb: 0, color: '#3875BF', fontWeight: '500', padding: '6px 8px' }}
                                                    variant="button"
                                                    display="block"
                                                    gutterBottom
                                                >
                                                    Suggested Donation|₹{puja.Amount}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                        ) : (
                            // <Typography>No Puja found</Typography>
                            <Box className="noPujaFound" sx={{mb: 5}}>
                                <img
                                className='noDataFound'
                                src={NoDataFound}
                                alt="nodatafound"
                                />
                                <Typography sx={{ color: '#8E8E8E', fontSize: '1.2rem', fontWeight: 500 }}>No Puja found!</Typography>
                            </Box>
                        )}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return{
        pujaList : state.puja.pujaList
  
    };
  
   }
   export default  connect(mapStateToProps,{
    getpujas,
   })(PujaBookingComponent);
