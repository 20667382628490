export const photos = [
    {
      src: "../temple-images/random-image1.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image2.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image3.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image4.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image5.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image6.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image7.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image8.png",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image9.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image10.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image11.jpg",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
    {
      src: "../temple-images/random-image12.png",
      alt: 'templeImage',
      width: 225,
      height: 225
    },
  ];