export const GETPUJAS                     = "[Puja] PUJA";
export const GETPUJA_SUCCESS               = "[Puja] Puja success";
export const GETPUJA_ERROR                 = "[Puja] Puja error";
export const SETPUJA                       = "[PUJA] Puja set";


export const GETPUJADETAILS                =  "[PujaDetails] PUJADETAILS";
export const GETPUJADETAILS_SUCCESS        = "[PujaDetails] PUJADETAILS success";
export const GETPUJADETAILS_ERROR          = "[PujaDetails] PUJADETAILS error";
export const SETPUJADETAILS                =  "[PujaDetails] PUJADETAILS SET";
export const SETPUJABOOKINGDETAILS         =  "[PUJABOOKINGDETAILS] PUJABOOKING SET";

export const PAYMENTPROCESS = "[PAYMENT PROCESS]";
export const PAYMENTSUCCESS   = "[PAYMENT SUCCESS] GET TRANS,SIGN,ORDER";
export const PAYMENTERROR = "['PAYMENT FAIL']";

export const GETPAYMENT = "[PAYMENTDETAILS]";
export const GETPAYMENTERROR =   "[PAYMENTDETAILS ERROR]";
export const GETPAYMENTSUCCESS =   "[PAYMENTDETAILS SUCCESS]";
export const SETPAYMENT =  "[PAYMENTDETAILS SET]";


export const PUJACOMPLETE = "[PUJA COMPLETE]"
export const PUJACOMPLETESUCCESS = "[PUJA COMPLETE SUCCESS]"
export const PUJACOMPLETEERROR = "[PUJA COMPLETE ERROR]"


export const getpujas = (data) => ({
    type: GETPUJAS,
    payload: data
  });
  export const getpujaSuccess = (data) => ({
    type: GETPUJA_SUCCESS ,
    payload: data,
  });
  
  export const getpujaError = (data) => ({
    type: GETPUJA_ERROR,
    payload: data,
  });

  export const setPuja = (data) => ({
    type: SETPUJA,
    payload: data,
  });

  export const getpujadetail = (data) => ({
    type: GETPUJADETAILS,
    payload : data
  });

  export const getpujadetailSuccess = (data) => ({
    type : GETPUJADETAILS_SUCCESS,
    payload : data,
  });

  export const getpujadetailError = (data) => ({
    type : GETPUJADETAILS_ERROR,
    payload : data,
  });

  export const setpujadetail = (data) => ({
    type : SETPUJADETAILS,
    payload : data,

  });

  export const setpujabookingdetails = (data) => ({
    type : SETPUJABOOKINGDETAILS,
    payload : data,
  });

  export const paymentprocess = (data) => ({
    type : PAYMENTPROCESS,
    payload : data,
  });

  export const paymentsuccess = (data) => ({
    type : PAYMENTSUCCESS,
    payload : data,
  });

  export const paymenterror = (data) => ({
    type : PAYMENTERROR,
    payload : data,
  });

  export const getpayment = (data) => ({
    type : GETPAYMENT,
    payload : data,
  });

  export const setpaymentdetails = (data) => ({
    type : SETPAYMENT,
    payload : data,
  });
   
  export const getpaymentdetailsError = (data) => ({
    type : GETPAYMENTERROR,
    payload : data,
  });

  export const getpaymentdetailsSuccess = (data) => ({
    type : GETPAYMENTSUCCESS,
    payload : data,
  })
 
  export const bookPujaComplete = (data) => ({
    type : PUJACOMPLETE,
    payload : data,
  });
   
  export const bookPujaCompleteSuccess = (data) => ({
    type : PUJACOMPLETESUCCESS,
    payload : data,
  });

  export const bookPujaCompleteError = (data) => ({
    type : PUJACOMPLETEERROR,
    payload : data,
  })


