import React from 'react';
import { 
  Box, 
  Button, 
  Container, 
  Divider, 
  Grid, 
  Step, 
  StepContent, 
  StepLabel, 
  Stepper, 
  Typography 
} from '@mui/material';
import '../MyDonationComponent/MyDonationComponent.css';

import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import MakeMyDonationComponent from '../MakeMyDonationComponent/MakeMyDonationComponent';
import ConfirmMyDonationComponent from '../ConfirmMyDonationComponent/ConfirmMyDonationComponent';
import PaymentDetailsComponent from '../PaymentDetailsComponent/PaymentDetailsComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';

import DashaDanam from '../../assets/Dasha-Danam.jpeg';

const steps = [
  {
    label: 'Make A Donation',
    MakeDonation: <MakeMyDonationComponent />
  },
  {
    label: 'Confirmation',
    DonationConfirmation: <ConfirmMyDonationComponent />
  },
  {
    label: 'Payment Details',
    PaymentDetails: <PaymentDetailsComponent />
  },
];

export default function MyDonationComponent() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const DashaDanam = require('../../assets/Dasha-Danam.jpeg');

  return (
    <>
        <HeaderComponent />
        <Box sx={{ mt: 3 }}>
          <Container>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={12} md={5}>
                <Box className="user-selected-donation" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                    <img
                        className='donation-image'
                        // src={String(DashaDanam)}
                        src={DashaDanam}
                        alt="donationImage"
                    />
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box>
                  <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#3875BF', mb: 2 }}>
                    Dasha-Danam
                  </Typography>
                  <Typography sx={{ lineHeight: 2.3, color: '#8E8E8E' }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/* <hr className='horizontal-line' /> */}
            <Divider />
          </Container>
        </Box>
        <Box className="make-donation-screen">
            <Container>
                <Box sx={{ py: 2 }}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                        <Step key={step.label} >
                          <Box className="stepper-label">
                            <StepLabel>
                              {step.label}
                            </StepLabel>
                          </Box>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                {step.MakeDonation}
                                {step.DonationConfirmation}
                                {step.PaymentDetails}
                                <Box className="stepContent-btn" sx={{ mb: 2, textAlign: 'right', mx: 20 }}>
                                      {/* {
                                        ()=>{
                                          if(!index === 0){
                                            <Button
                                              disabled={index === 0}
                                              variant="outlined"
                                              onClick={handleBack}
                                              sx={{ mt: 1, mr: 1 }}
                                          >
                                              Back
                                          </Button>
                                          }
                                        }
                                      } */}
                                        <Button
                                            disabled={index === 0}
                                            variant="outlined"
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                          Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                          {index === steps.length - 1 ? 'Submit' : 'Next'}
                                        </Button>
                                </Box>
                                {/* <hr className='horizontal-line' /> */}
                                <Divider />
                            </StepContent>
                        </Step>
                        ))}
                  </Stepper>
                </Box>
            </Container>
            <FooterComponent />
        </Box>
        <BottomAppBarComponent />
    </>
  )
}
