import { apiRequest } from "../../actions/api";
import {

    GETPUJAS,
    GETPUJA_SUCCESS,
    GETPUJA_ERROR,

    GETPUJADETAILS,
    GETPUJADETAILS_SUCCESS,
    GETPUJADETAILS_ERROR,
    
    GETPAYMENT,
    GETPAYMENTSUCCESS,
    GETPAYMENTERROR,

    PAYMENTPROCESS,
    PAYMENTSUCCESS,
    PAYMENTERROR,

    PUJACOMPLETE,
    PUJACOMPLETESUCCESS,
    PUJACOMPLETEERROR,

    getpujaSuccess,
    getpujaError,
    setPuja,
    getpujadetailSuccess,
    getpujadetailError,
    setpujadetail,

    paymentprocess,
    paymentsuccess,
    paymenterror,

    getpaymentdetailsSuccess,
    getpaymentdetailsError,
    setpaymentdetails,

    bookPujaComplete,
    bookPujaCompleteSuccess,
    bookPujaCompleteError
} from "../../actions/puja";


export const getallpuja = ({ dispatch }) => (next) => (action) => {
    console.log("Puja list")
    next(action);
    if (action.type === GETPUJAS) {
        dispatch(
            apiRequest(
                "GET",
                "apiCall.php?apicall=pujalist",
                action.payload,
                null,
                getpujaSuccess,
                getpujaError,
                false,
                "['Puja']"
            )
        );

    }
};

export const progressGetPuja = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETPUJA_SUCCESS) {
        dispatch(setPuja(action.payload.data));
    }

    if (action.type === GETPUJA_ERROR) {

    }
}


export const getallpujadetails = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETPUJADETAILS) {
        console.log(action.payload)
        dispatch(
            apiRequest(
                "GET",
                "apiCall.php?apicall=pujadetails&PujaID=" + action.payload,
                null,
                null,
                getpujadetailSuccess,
                getpujadetailError,
                false,
                "['PujaDetails']"
            )
        );
    }
};

export const progressGetallpujadetails = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETPUJADETAILS_SUCCESS) {
        var data = action.payload.data[0];
        console.log(data);
        dispatch(setpujadetail(data));

    }
    if (action.type === GETPUJADETAILS_ERROR) {

    }
}

export const getpaymentdetails = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETPAYMENT) {
        dispatch(
            apiRequest(
                "GET",
                "apiCall.php?apicall=paymentdetails",
                action.payload,
                null,
                getpaymentdetailsSuccess,
                getpaymentdetailsError,
                false,
                ['PAYMENTDETAILS']
            )
        )
    }

}

export const setPayment = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETPAYMENTSUCCESS) {
        var data = action.payload.data;
        dispatch(setpaymentdetails(data));
        console.log("PAYMENT SUCCESS");
    }
    if (action.type === GETPAYMENTERROR) {
        console.log("PAYMENTDETAILS FAILED");
    }

}

export const paymentProcess = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === PAYMENTPROCESS) {
        dispatch(
            apiRequest(
                "POST",
                "apiCall.php?apicall=pujaPayment",
                null,
                action.payload,
                paymentsuccess,
                paymenterror,
                false,
                ['PAYMENTDETAILS']
            )
        )
    }
}

export const payment = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === PAYMENTSUCCESS) {
        var data = action.payload.data;
        dispatch((data));
        console.log("PAYMENT CAME TO SUCCESS");
    }
    if (action.type === PAYMENTERROR) {
        console.log("PAYMENT FAILED");
    }
}

export const bookPujaFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === PUJACOMPLETE) {
        dispatch(
            apiRequest(
                "POST",
                "apiCall.php?apicall=pujaPaymentCreation",
                null,
                action.payload,
                bookPujaCompleteSuccess,
                bookPujaCompleteError,
                false,
                ['PAYMENTDETAILS']
            )
        )
    }
}

export const bookPujaProcess = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === PUJACOMPLETESUCCESS) {

    }

    if (action.type === PUJACOMPLETEERROR) {
    }
}


export const pujamodel = [
    getallpuja,
    progressGetPuja,
    getallpujadetails,
    progressGetallpujadetails,
    getpaymentdetails,
    setPayment,
    paymentProcess,
    payment,
    bookPujaFlow,
    bookPujaProcess


    

]