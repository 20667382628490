import React from 'react';

import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import PujaBookingComponent from '../PujaBookingComponent/PujaBookingComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';


export default function PujaGalleryComponent() {
  return (
    <>
      <HeaderComponent />
      <PujaBookingComponent />
      <FooterComponent />
      <BottomAppBarComponent />
    </>
  )
}
