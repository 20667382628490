import React from 'react';
import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import DonationGalleryComponent from '../DonationGalleryComponent/DonationGalleryComponent';

export default function MakeDonationGalleryComponent() {
  return (
    <>
        <HeaderComponent />
        <DonationGalleryComponent />
        <FooterComponent />
        <BottomAppBarComponent />
    </>
  )
}
