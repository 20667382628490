import React from 'react';
import { 
  Box, 
  Typography 
} from '@mui/material';

import { Link } from 'react-router-dom';
import '../FooterComponent/FooterComponent.css';

export default function FooterComponent() {
  return (
    <>
        <Box className="copyright-content" sx={{ textAlign: 'center', py: 3 }}>
            <Typography sx={{ color: '#686868' }}>Copyright © 2024. All Rights Reserved|
            <Link to="/aboutus" > About us</Link> |
            <Link to="/terms" > Terms & Conditions</Link> |
            <Link to="/contactus" > Contact us</Link> |
            <Link to="/privacy" > Privacy policy</Link> |
            <Link to="/refund" > Refund</Link>.
            </Typography>
        </Box>
    </>
  )
}
